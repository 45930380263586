@tailwind base;
@tailwind components;
@tailwind utilities;

/* Webfont: AkzidenzGroteskPro-Light */
@font-face {
    font-family: "Akzidenz-GroteskProLight";
    src: url("/font/AkzidGrtskProLig.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProLig.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProLig.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProLig.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProLig.svg#AkzidenzGroteskPro-Light")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-LightIt */
@font-face {
    font-family: "Akzidenz-GroteskProLightIt";
    src: url("/font/AkzidGrtskProLigIta.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProLigIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProLigIta.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProLigIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProLigIta.svg#AkzidenzGroteskPro-LightIt")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-Regular */
@font-face {
    font-family: "Akzidenz-GroteskProRegular";
    src: url("/font/AkzidGrtskProReg.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProReg.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProReg.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProReg.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProReg.svg#AkzidenzGroteskPro-Regular")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-It */
@font-face {
    font-family: "Akzidenz-GroteskProRegularIt";
    src: url("/font/AkzidGrtskProIta.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProIta.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProIta.svg#AkzidenzGroteskPro-It")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-Md */
@font-face {
    font-family: "Akzidenz-GroteskProMed";
    src: url("/font/AkzidGrtskProMed.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProMed.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProMed.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProMed.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProMed.svg#AkzidenzGroteskPro-Md")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-MdIt */
@font-face {
    font-family: "Akzidenz-GroteskProMedIt";
    src: url("/font/AkzidGrtskProMedIta.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProMedIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProMedIta.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProMedIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProMedIta.svg#AkzidenzGroteskPro-MdIt")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-Bold */
@font-face {
    font-family: "Akzidenz-GroteskProBold";
    src: url("/font/AkzidGrtskProBol.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProBol.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProBol.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProBol.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProBol.svg#AkzidenzGroteskPro-Bold")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-BoldIt */
@font-face {
    font-family: "Akzidenz-GroteskProBoldIt";
    src: url("/font/AkzidGrtskProBolIta.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProBolIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProBolIta.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProBolIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProBolIta.svg#AkzidenzGroteskPro-BoldIt")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-Super */
@font-face {
    font-family: "Akzidenz-GroteskProSuper";
    src: url("/font/AkzidGrtskProSup.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProSup.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProSup.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProSup.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProSup.svg#AkzidenzGroteskPro-Super")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: AkzidenzGroteskPro-SuperItalic */
@font-face {
    font-family: "Akzidenz-GroteskProSuperIt";
    src: url("/font/AkzidGrtskProSupIta.eot"); /* IE9 Compat Modes */
    src: url("/font/AkzidGrtskProSupIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/font/AkzidGrtskProSupIta.woff") format("woff"),
        /* Modern Browsers */ url("/font/AkzidGrtskProSupIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/font/AkzidGrtskProSupIta.svg#AkzidenzGroteskPro-SuperItalic")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* ! CHRONICLE */

/* Webfont: ChronicleDisplay-Black */
@font-face {
    font-family: "ChronicleDisplayBlack";
    src: url("/fonts/ChronDisBla.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronDisBla.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronDisBla.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronDisBla.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronDisBla.svg#ChronicleDisplay-Black") format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleDisplay-Semi */
@font-face {
    font-family: "ChronicleDisplaySemi";
    src: url("/fonts/ChronDisSem.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronDisSem.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronDisSem.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronDisSem.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronDisSem.svg#ChronicleDisplay-Semi") format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleDisplay-Roman */
@font-face {
    font-family: "ChronicleDisplay";
    src: url("/fonts/ChronicleDisp-Roman.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronicleDisp-Roman.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronicleDisp-Roman.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronicleDisp-Roman.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronicleDisp-Roman.svg#ChronicleDisplay-Roman")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleDisplay-SemiItalic */
@font-face {
    font-family: "ChronicleDisplaySemiItalic";
    src: url("/fonts/ChronicleDisp-SemiboldItal.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronicleDisp-SemiboldItal.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronicleDisp-SemiboldItal.woff")
            format("woff"),
        /* Modern Browsers */ url("/fonts/ChronicleDisp-SemiboldItal.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronicleDisp-SemiboldItal.svg#ChronicleDisplay-SemiItalic")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleTextG1-Roman */
@font-face {
    font-family: "ChronicleTextG1";
    src: url("/fonts/ChronTexG1Rom.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronTexG1Rom.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronTexG1Rom.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronTexG1Rom.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronTexG1Rom.svg#ChronicleTextG1-Roman") format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleTextG1-Semi */
@font-face {
    font-family: "ChronicleTextG1Semi";
    src: url("/fonts/ChronicleTextG1-Semibold.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronicleTextG1-Semibold.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronicleTextG1-Semibold.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronicleTextG1-Semibold.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronicleTextG1-Semibold.svg#ChronicleTextG1-Semi")
            format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleTextG1-Semi */
@font-face {
    font-family: "ChronicleTextG1SemBd";
    src: url("/fonts/ChronTexG1Sem.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronTexG1Sem.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronTexG1Sem.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronTexG1Sem.ttf") format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronTexG1Sem.svg#ChronicleTextG1-Semi") format("svg"); /* Legacy iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

/* Webfont: ChronicleTextG1-SemiItalic */
@font-face {
    font-family: "ChronicleTextG1SemBdIt";
    src: url("/fonts/ChronTexG1SemIta.eot"); /* IE9 Compat Modes */
    src: url("/fonts/ChronTexG1SemIta.eot?#iefix") format("embedded-opentype"),
        /* IE6-IE8 */ url("/fonts/ChronTexG1SemIta.woff") format("woff"),
        /* Modern Browsers */ url("/fonts/ChronTexG1SemIta.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("/fonts/ChronTexG1SemIta.svg#ChronicleTextG1-SemiItalic")
            format("svg"); /* Legacy iOS */
    font-style: italic;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}

.button {
    @apply inline-flex items-center px-4 py-2 bg-gray-100 text-gray-800 border border-transparent rounded-md font-semibold text-xs tracking-widest transition ease-in-out duration-150 ring-gray-300;
}

.button:hover {
    @apply bg-gray-200;
}

.button:active,
.button:focus {
    @apply bg-gray-300 ring;
}

.button.button-primary {
    @apply bg-indigo-500 text-white !important;
}

.button-primary:hover,
.button.button-primary:active,
.button.button-primary:focus {
    @apply bg-indigo-600 !important;
}

.button.button-primary:disabled {
    @apply bg-indigo-200 !important;
}

.button.button-danger {
    @apply bg-red-500 text-white !important;
}

.button-danger:hover,
.button.button-danger:active,
.button.button-danger:focus {
    @apply bg-red-600 !important;
}

.button.button-danger:disabled {
    @apply bg-red-200 !important;
}

/* ! PUBLICATION TITLE*/
.rx-content .publication_title {
    font-size: 1.7em;
    line-height: 1.2em;
    font-family: "ChronicleDisplaySemi";
    margin-bottom: 20px;
    color: #3b4c54 !important;
}

.redactor-styles h1,
.redactor-dropdown-item-publication_title,
.redactor-styles .publication_title {
    font-size: 1.7em;
    line-height: 1.2em;
    font-family: "ChronicleDisplaySemi";
    margin-bottom: 20px;
    color: #3b4c54 !important;
}
.redactor-dropdown-item-publication_title {
    margin-bottom: 0px;
}
.redactor-dropdown {
    max-height: 1000px !important;
}

.redactor-dropdown-item-publication_title {
    font-size: 36px;
    line-height: 36px;
}

/* ! PUBLICATION SUMMARY BULLET */
.redactor-styles h2,
.redactor-dropdown-item-summary_bullet,
.redactor-styles .summary_bullet,
.summary_bullet {
    font-family: "Akzidenz-GroteskProBold";
    font-size: 1em;
    line-height: 1.6em;
    color: #299c92 !important;
}

/* ! PUBLICATION WEEKLY SUBTITLE */
.redactor-styles h3,
.redactor-dropdown-item-weekly_subtitle,
.redactor-styles .weekly_subtitle,
.weekly_subtitle {
    /*font-family: "ChronicleTextG1SemBd";
    font-size: 1.2em;
    color: #3b4c54 !important;
    */
    font-family: "Akzidenz-GroteskProBold";
    font-size: 1.8rem !important;
    color: #299c92 !important;

    /*border-top: 1px solid #299c92;*/
    padding-top: 8px;
    margin-bottom: 0.5rem;
}

/* ! PUBLICATION IN-DEPTH SUBTITLE */
.redactor-styles h4,
.redactor-dropdown-item-in_depth_subtitle,
.redactor-styles .in_depth_subtitle,
.in_depth_subtitle {
    font-family: "ChronicleTextG1Semi";
    font-size: 1.2em;
    line-height: 1em;
    margin-bottom: 10px;
    color: #3b4c54 !important;
}

/* ! PUBLICATION BODY TEXT */
.redactor-styles p,
.redactor-styles ul li,
.redactor-dropdown-item-body_text,
.redactor-styles .body_text {
    font-family: "ChronicleTextG1";
    font-size: 1em;
    color: #3b4c54;
}

.redactor-styles ul {
    list-style: none;
    margin: 0px;
    margin-left: 2em;
}
.redactor-styles ul li{
    margin-top: 1em;
}
#introduction ul li {
    color: #299c92 !important;
}

.redactor-styles ul li:before {
    content: "● ";
    color: #299c92 !important;
    font-size: 20px;
    line-height: 24px;
    margin-right: 10px;
    float: left;
}
.redactor_text ol,
.redactor-styles ol {
    list-style: initial !important;
    list-style-type: decimal !important;
}

#description ul li {
    color: #3b4c54 !important;
    margin-top: 1em;
}

.redactor-styles a {
    color: #299c92 !important;
}

/* ! PUBLICATION CHART TITLE */
.redactor-styles h5,
.redactor-dropdown-item-chart_title,
.redactor-styles .chart_title {
    font-family: "Akzidenz-GroteskProBold";
    font-size: 1.2em;
    color: #299c92 !important;
}

/* ! PUBLICATION CHART SUBTITLE */
.redactor-styles h6,
.redactor-dropdown-item-chart_subtitle,
.redactor-styles .chart_subtitle {
    font-family: "Akzidenz-GroteskProLight";
    font-size: 1em;
    color: #3b4c54 !important;
}
/* ! PUBLICATION CHART SUBTITLE */
.redactor-styles h6,
.redactor-dropdown-item-chart_subtitle,
.redactor-styles .chart_sub_title {
    font-family: "Akzidenz-GroteskProRegular";
    font-size: 1em;
    color: #3b4c54 !important;
}

/* ! PUBLICATION CHART SOURCE */
.redactor-styles h7,
.redactor-dropdown-item-chart_source,
.redactor-styles .chart_source {
    font-family: "Akzidenz-GroteskProLight";
    font-size: 0.9em !important;
    color: #3b4c54 !important;
}

/* ! PUBLICATION HIGHLIGHT TEXT */
.redactor-dropdown-item-highlight_text,
.redactor-styles .highlight_text,
.highlight_text {
    font-family: "ChronicleTextG1" !important;
    font-size: 1.4em !important;
    color: #299c92 !important;
    display: block;
    padding-left: 20px !important;
    margin-top: 1.2em;
    /*border-left: 1px solid #299c92;*/
}

/* ! PUBLICATION QUOTE */
.redactor-styles blockquote,
.redactor-dropdown-item-quotes,
.redactor-styles .quotes,
.quotes {
    font-family: "ChronicleDisplaySemiItalic" !important;
    font-size: 1.1em;
    color: #3b4c54 !important;
    border-left: none;
    font-style: normal;
    padding-left: 0px !important;
    font-style: italic;
}

.quotes::before {
    content: " ";
    width: 92px;
    height: 61px;
    background: transparent url(/img/open_quote_grey_92.png) no-repeat;
    display: block;
}
.quotes {
    margin-bottom: 1rem;
}

/*.quotes::after {
  content: " ";
  width:32px;
  height:21px;
  background: transparent url(/img/close_quote_grey_32.png) no-repeat;
  display:inline-block;
}
*/
.redactor-dropdown {
    min-width: 320px;
}
.redactor-dropdown a:focus {
    background-color: #299c92;
}

.publication_preview table,
.redactor-component table {
    font-family: "Akzidenz-GroteskProRegular", "Helvetica Neue", Helvetica,
        Tahoma, sans-serif;
    font-size: 1rem;
    line-height: 24px;
    color: #222;
    width: 100%;
}

.publication_preview table thead,
.publication_preview table tbody,
.publication_preview table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe;
}
.publication_preview .table_header {
    background-color: #299c92 !important;
    color: #fff !important;
}

.publication_preview .table_subheader {
    background-color: #3b4c54 !important;
    color: #fff !important;
}

.publication_preview table th,
.publication_preview table td {
    border: 1px solid #eee;
    padding: 16px;
    padding-bottom: 15px;
}
.publication_preview table tbody tr:nth-child(even) {
    background-color: #f1f1f1;
}

.redactor-styles .table_header,
.table_header {
    background-color: #299c92;
    color: #fff;
}

.redactor-styles .table_subheader,
.table_subheader {
    background-color: #3b4c54;
    color: #fff;
}

.redactor-styles .table_footer,
.table_footer {
    background-color: #a6b1b8;
    color: #3b4c54;
}
.redactor-styles table,
.chart_table {
    border-collapse: collapse;
    max-width: 100%;
    width: 100%;
    font-family: "Akzidenz-GroteskProRegular", "Helvetica Neue", Helvetica,
        Tahoma, sans-serif;
    line-height: 24px;
    color: #222;
}

.redactor-styles table td,
.chart_table td {
    border: 1px solid #eee;
    padding: 16px;
    padding-bottom: 15px;
}
.chart_title {
    font-family: "Akzidenz-GroteskProBold";
    font-size: 1em;
    color: #299c92;
}
.chart_subtitle {
    font-family: "Akzidenz-GroteskProLight";
    font-size: 1em;
    color: #3b4c54 !important;
}
.chart_source {
    color: #3b4c54;
    font-family: "Akzidenz-GroteskProRegular";
    font-size: 0.9em !important;
    margin-left: 11px;
}
.chart_actions {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -17px;
}
.chart_actions,
.remove_from_favourites a {
    font-family: "Akzidenz-GroteskProRegular";
    color: #299c92;
    font-size: 0.8em;
    display: block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.chart_actions img,
.remove_from_favourites a img {
    display: inline;
}

.chart_actions .redactor-component,
.chart_actions p {
    float: left;
    margin-right: 10px;
    height: 3px;
}
#appendix,
.redactor_sans_serif .redactor-styles,
.redactor_sans_serif .redactor-styles p {
    font-family: "Akzidenz-GroteskProRegular";
    font-size: 1em;
    color: #3b4c54;
}

.redactor-modal input[type="checkbox"],
.redactor-modal input[type="radio"] {
    width: 1rem !important;
    height: 1rem !important;
}

ion-icon {
    pointer-events: none;
}

[x-cloak] {
    display: none !important;
}

@import "select2.min.css";
@import "multi-select.css";
